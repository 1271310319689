import { useCallback } from 'react';
import { useAnnotations } from './useRenderer';
import { useDispatch } from 'react-redux';
import { useCustomSelector } from '../../redux/store';
import { AnnotationProps } from '../../modules/renderer/RendererLeftPanel/Annotations/Annotation';
import { mergeSelectedAnnotations, toggleSelectedAnnotation } from '../../redux/rendererReducer';
import { AnnotationContextGroup } from '../../contexts/AnnotationContext';

export const useSelectAnnotation = () => {
  const dispatch = useDispatch();
  const [{ indexedAnnotations }] = useAnnotations();
  const { lastSelectedAnnotation } = useCustomSelector(
    (state) => state.rendererProvider,
    ['selectedAnnotations', 'lastSelectedAnnotation'],
  );

  const onClickAnnotation: AnnotationProps['onClickAnnotation'] = useCallback(
    (selectedAnnotation, { isCtrl, isShift }) => {
      if (!isShift) return dispatch(toggleSelectedAnnotation({ ...selectedAnnotation, clearOtherSelections: !isCtrl }));
      if (!lastSelectedAnnotation) return;
      if (!lastSelectedAnnotation.groupIdentifier) {
        const lastSelectedIndex = indexedAnnotations.findIndex(
          (annotation) => annotation.identifier === lastSelectedAnnotation?.identifier,
        );
        const newSelectedIndex = indexedAnnotations.findIndex(
          (annotation) => annotation.identifier === selectedAnnotation.identifier,
        );
        if (newSelectedIndex === -1) return;
        return dispatch(
          mergeSelectedAnnotations({
            annotations: indexedAnnotations
              .slice(Math.min(lastSelectedIndex, newSelectedIndex), Math.max(lastSelectedIndex, newSelectedIndex) + 1)
              .map((annotation) => ({
                identifier: annotation.identifier,
                type: annotation.__typename === 'AnnotationGroup' ? 'group' : 'annotation',
              })),
          }),
        );
      }
      const lastSelectedGroupIndex = indexedAnnotations.findIndex(
        (annotation) => annotation.identifier === lastSelectedAnnotation.groupIdentifier,
      );
      const annotationGroup = indexedAnnotations[lastSelectedGroupIndex] as AnnotationContextGroup;
      const lastSelectedIndex = annotationGroup.annotations.findIndex(
        (annotation) => annotation.identifier === lastSelectedAnnotation?.identifier,
      );
      const newSelectedIndex = annotationGroup.annotations.findIndex(
        (annotation) => annotation.identifier === selectedAnnotation.identifier,
      );
      if (newSelectedIndex === -1) return;
      return dispatch(
        mergeSelectedAnnotations({
          annotations: annotationGroup.annotations
            .slice(Math.min(lastSelectedIndex, newSelectedIndex), Math.max(lastSelectedIndex, newSelectedIndex) + 1)
            .map((annotation) => ({
              identifier: annotation.identifier,
              type: 'annotation',
              groupIdentifier: annotationGroup.identifier,
            })),
        }),
      );
    },
    [indexedAnnotations, dispatch, lastSelectedAnnotation],
  );
  return { onClickAnnotation };
};
