import { ApolloProvider } from '@apollo/client';
import { Analytics } from '@vercel/analytics/react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { apolloClient } from './apollo';
import { ProtectedLayout } from './auth/ProtectedLayout';
import { AdminCenter } from './pages/admin-center';
import { ForgotPassword } from './pages/authentication/ForgotPassword';
import { InvalidSubscription } from './pages/authentication/InvalidSubscription';
import { Invitation } from './pages/authentication/Invitation';
import { Login } from './pages/authentication/Login';
import { Logout } from './pages/authentication/Logout';
import { Register } from './pages/authentication/Register';
import { ResetPassword } from './pages/authentication/ResetPassword';
import { Billing } from './pages/billing';
import { CompanyInfo } from './pages/billing/companyInfo';
import { PaymentHistory } from './pages/billing/history';
import { Organisations } from './pages/organisations';
import PotreeRenderer from './pages/potree-renderer';
import { Projects } from './pages/projects';
import { Project } from './pages/projects/Project';
import { Users } from './pages/users';
import { Profile } from './pages/users/Profile';
import WATest from './pages/wa-test';
import store from './redux/store';
import { Language, Permissions } from './types/graphqlTypes';
import { ErrorBoundary } from './pages/error-boundary';
import { InvalidProject } from './pages/projects/invalidProject';
import { NoOrganisationFound } from './pages/organisations/NoOrganisationFound';
import { LanguageProvider } from './translation/src';
import { useLocalStorage } from 'usehooks-ts';

import EN from '../translations/en.json';
import DE from '../translations/de.json';
import NL from '../translations/nl.json';
import FR from '../translations/fr.json';
import JA from '../translations/ja.json';
import PT from '../translations/pt.json';
import App from './app/app';
import { ApiKeys } from './pages/api/ApiKeys';

const Home = () => {
  return <div />;
};

export const AppContainer: React.FC = () => {
  const [language, setLanguage] = useLocalStorage('lang', Language.En);

  return (
    <ReduxProvider store={store}>
      {process.env.NODE_ENV === 'local' ? null : <Analytics />}
      <ApolloProvider client={apolloClient}>
        <LanguageProvider language={language} languages={{ EN, NL, DE, FR, JA, PT }} setLanguage={setLanguage}>
          <ErrorBoundary>
            <BrowserRouter>
              <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path="/register" element={<Register />} />
                <Route path="/register-invite" element={<Invitation />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/" element={<App />}>
                  <Route index element={<Home />} />
                  <Route path="potree-renderer" element={<PotreeRenderer />} />
                  <Route path="wa-test" element={<WATest />} />
                  <Route path="organisations" element={<Organisations />}>
                    <Route path=":organisationId">
                      <Route
                        index
                        element={
                          <ProtectedLayout permissions={[Permissions.Projects, Permissions.ProjectsRead]}>
                            <Projects />
                          </ProtectedLayout>
                        }
                      />
                      <Route
                        path="projects"
                        element={<ProtectedLayout permissions={[Permissions.Projects, Permissions.ProjectsRead]} />}
                      >
                        <Route index element={<Projects />} />
                        <Route path=":projectId" element={<Project />} />
                      </Route>
                      <Route path="invalid-project">
                        <Route index element={<InvalidProject />} />
                      </Route>
                      <Route path="users" element={<ProtectedLayout permissions={[Permissions.Users]} />}>
                        <Route index element={<Users />} />
                      </Route>
                      <Route path="billing" element={<ProtectedLayout permissions={[Permissions.Billing]} />}>
                        <Route index element={<Billing />} />
                        <Route path="history">
                          <Route index element={<PaymentHistory />} />
                        </Route>
                        <Route path="users" element={<ProtectedLayout permissions={[Permissions.Users]} />}>
                          <Route index element={<Users />} />
                        </Route>
                        <Route path="companyInfo">
                          <Route index element={<CompanyInfo />} />
                        </Route>
                      </Route>
                      <Route path="api-keys">
                        <Route index element={<ApiKeys />} />
                      </Route>
                      <Route path="invalid-subscription">
                        <Route index element={<InvalidSubscription />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route path="no-organisation">
                    <Route index element={<NoOrganisationFound />} />
                  </Route>
                  <Route path="me">
                    <Route path="profile" element={<Profile />} />
                  </Route>
                  <Route path="admin-center">
                    <Route index element={<AdminCenter />} />
                  </Route>
                </Route>
              </Routes>
            </BrowserRouter>
          </ErrorBoundary>
          <ToastContainer />
        </LanguageProvider>
      </ApolloProvider>
    </ReduxProvider>
  );
};
