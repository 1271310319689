import classNames from 'classnames';
import { FormikConfig, FormikHelpers, useFormikContext } from 'formik';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { CreateModal } from '../../components/Modal/CreateModal';
import { FormikInput } from '../../components/formik/FormInput';
import { T, useT } from '../../translation/src';
import { SubscriptionType, useSuperadminUpdatePlanMutation } from '../../types/graphqlTypes';
import { FormikSelect } from '../../components/formik/FormSelect';
import { Option } from '../../components/inputs/Select';
import { usePlanOptions } from '../../hooks/usePlanOptions';
import moment from 'moment';

const MAX_SIMULTANEOUS_UPLOADS_LIMIT_BY_TYPE: Record<SubscriptionType, number> = {
  [SubscriptionType.Grow]: 3,
  [SubscriptionType.Launch]: 3,
  [SubscriptionType.Scale]: 5,
  [SubscriptionType.Custom]: 0,
  [SubscriptionType.Free]: 1,
};

const MAX_POINT_COUNT_LIMIT_BY_TYPE = {
  [SubscriptionType.Grow]: 50000000000,
  [SubscriptionType.Launch]: 5000000000,
  [SubscriptionType.Scale]: 500000000000,
  [SubscriptionType.Custom]: 0,
  [SubscriptionType.Free]: 500000000,
};

const MAX_MONTHLY_POINT_COUNT_LIMIT_BY_TYPE = {
  [SubscriptionType.Grow]: 5000000000,
  [SubscriptionType.Launch]: 500000000,
  [SubscriptionType.Scale]: 50000000000,
  [SubscriptionType.Custom]: 0,
  [SubscriptionType.Free]: 500000000,
};

interface FormValues {
  subscriptionType: SubscriptionType;
  maxPointCount: number;
  maxMonthlyPointCount: number;
  maxUploadsInProgress: number;
  endDate: string;
}

const LimitsHandler: React.FC = () => {
  const formikContext = useFormikContext<FormValues>();

  useEffect(() => {
    formikContext.setFieldValue('maxPointCount', MAX_POINT_COUNT_LIMIT_BY_TYPE[formikContext.values.subscriptionType]);
    formikContext.setFieldValue(
      'maxMonthlyPointCount',
      MAX_MONTHLY_POINT_COUNT_LIMIT_BY_TYPE[formikContext.values.subscriptionType],
    );
    formikContext.setFieldValue(
      'maxUploadsInProgress',
      MAX_SIMULTANEOUS_UPLOADS_LIMIT_BY_TYPE[formikContext.values.subscriptionType],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formikContext.values.subscriptionType]);

  return null;
};

const useTranslations = () => {
  const maxPointCount = useT('max point count', { swc: true });
  const maxUploadsInProgress = useT('max simultaneous uploads', { swc: true });
  const maxMonthlyPointCount = useT('max monthly point count', { swc: true });
  const plan = useT('plan', { swc: true });
  const endDate = useT('end date', { swc: true });
  const changePlan = useT('change plan', { swc: true });

  return { maxPointCount, maxUploadsInProgress, maxMonthlyPointCount, plan, endDate, changePlan };
};

interface ChangePlanModalProps {
  onClose: () => void;
  open?: boolean;
  organisationId?: string;
  initialValues?: {
    maxPointCount: number;
    maxUploadsInProgress: number;
    maxMonthlyPointCount: number;
    subscriptionType: SubscriptionType;
    endDate: string;
  };
}
const $ChangePlanModal: React.FC2<ChangePlanModalProps> = ({ onClose, open, organisationId, initialValues }) => {
  const [updatePlan, { loading: updatePlanLoading }] = useSuperadminUpdatePlanMutation();
  const translations = useTranslations();
  const planOptions = usePlanOptions();

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      onClose();
      // Because of transition
      setTimeout(() => {
        resetForm();
      }, 250);
    },
    [onClose],
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values, helpers) => {
      if (!organisationId || !initialValues) return;
      await updatePlan({
        variables: {
          organisationId,
          subscriptionType: values.subscriptionType,
          customLimits: {
            maxPointCount: values.maxPointCount,
            maxUploadsInProgress: values.maxUploadsInProgress,
            maxMonthlyPointCount: values.maxMonthlyPointCount,
          },
          endDate: values.endDate,
        },
      });
      onSuccess({ helpers });
    },
    [initialValues, onSuccess, organisationId, updatePlan],
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: initialValues || {
        subscriptionType: SubscriptionType.Free,
        maxPointCount: 0,
        maxUploadsInProgress: 0,
        maxMonthlyPointCount: 0,
        endDate: moment().format('YYYY-MM-DD'),
      },
      onSubmit,
    }),
    [onSubmit, initialValues],
  );

  return (
    <CreateModal
      title={translations.changePlan}
      createButtonTitle={<T _str="submit" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
      isSubmitting={updatePlanLoading}
    >
      <LimitsHandler />
      <div className={classNames('flex flex-col')}>
        <FormikSelect name="subscriptionType" label={translations.plan} placeholder={translations.plan}>
          {planOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </FormikSelect>
        <FormikInput type="date" autoFocus name="endDate" label={translations.endDate} />
        <FormikInput type="number" autoFocus name="maxPointCount" label={translations.maxPointCount} />
        <FormikInput type="number" autoFocus name="maxMonthlyPointCount" label={translations.maxMonthlyPointCount} />
        <FormikInput type="number" autoFocus name="maxUploadsInProgress" label={translations.maxUploadsInProgress} />
      </div>
    </CreateModal>
  );
};

export const ChangePlanModal = memo($ChangePlanModal);
