import { useMemo } from 'react';
import { SubscriptionType } from '../types/graphqlTypes';
import { useT } from '../translation/src';

export const usePlanOptions = () => {
  const free = useT('free', { swc: true });
  const launch = useT('launch', { swc: true });
  const grow = useT('grow', { swc: true });
  const scale = useT('scale', { swc: true });
  const custom = useT('custom', { swc: true });

  const options = useMemo(() => {
    return [
      { value: SubscriptionType.Free, label: free },
      { value: SubscriptionType.Launch, label: launch },
      { value: SubscriptionType.Grow, label: grow },
      { value: SubscriptionType.Scale, label: scale },
      { value: SubscriptionType.Custom, label: custom },
    ];
  }, [custom, free, grow, launch, scale]);

  return options;
};
