import { useMemo } from 'react';
import { ApiKeyType } from '../types/graphqlTypes';
import { useT } from '../translation/src';

export const useKeyTypeOptions = () => {
  const defaultKey = useT('default key', { swc: true });
  const licenseKey = useT('license key ', { swc: true });

  const options = useMemo(() => {
    return [
      { value: ApiKeyType.Default, label: defaultKey },
      { value: ApiKeyType.WithLicenseKey, label: licenseKey },
    ];
  }, [defaultKey, licenseKey]);
  return options;
};
