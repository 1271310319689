import React, { createContext } from 'react';
import { Language } from '../../../types/graphqlTypes';

export interface ILanguageContext {
  language: Language;
  languages: Record<Language, { [key: string]: any }>;
  setLanguage: (e: Language) => void;
}

export const LanguageContext = createContext<ILanguageContext>({
  language: Language.En,
  languages: {
    DE: {},
    EN: {},
    FR: {},
    JA: {},
    NL: {},
    PT: {},
  },
  setLanguage: (e: Language) => e,
});

export const LanguageProvider: React.FC2<ILanguageContext> = ({ children, language, languages, setLanguage }) => {
  return <LanguageContext.Provider value={{ language, languages, setLanguage }}>{children}</LanguageContext.Provider>;
};
