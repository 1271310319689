import { memo, useContext, useMemo } from 'react';
import { ConvertType, useFormatNumber } from '../../../hooks/useFormatNumber';
import { T } from '../../../translation/src';
import { AnnotationLabels, AreaAnnotation } from '../../../types/graphqlTypes';
import { EditableColorProperty, StaticColorProperty } from './ColorProperties';
import { PropertiesSection, Property } from './PropertiesSection';
import { useMeasurements } from '../../../hooks/useMeasurements';
import { HideLabelProperty } from './HideLabel';
import { ICadPolygon } from '../../../utils/CADFileParser';
import { RendererContext } from '../../../contexts/RendererContext';

interface AreaPropertiesProps {
  annotation: AreaAnnotation;
}
const $AreaProperties: React.FC2<AreaPropertiesProps> = ({ annotation }) => {
  const { formatNumber } = useFormatNumber();
  const { perimeterMeasurement } = useMeasurements();

  const renderContext = useContext(RendererContext);
  const viewer = renderContext.viewer;
  const polygonObject = viewer?.scene.measurements.find((m) => m.identifier === annotation.identifier);
  const area = polygonObject?.getArea() || 0;
  const planar = polygonObject?.isPlanar() || false;

  const perimeter = useMemo(() => {
    return perimeterMeasurement(annotation.points.map((point) => point.position));
  }, [annotation.points, perimeterMeasurement]);

  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="polygon" swc />
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="measurements" swc />}>
        <Property title={planar ? <T _str="area (3D)" swc /> : <T _str="area (2D)" swc />}>
          {formatNumber(area, { convertType: ConvertType.AREA, addUnit: true })}
        </Property>
        <Property title={<T _str="perimeter" swc />}>
          {formatNumber(perimeter, { convertType: ConvertType.DISTANCE, addUnit: true })}
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="appearance" swc />}>
        <HideLabelProperty annotations={[annotation]} label={AnnotationLabels.Length} />
        <HideLabelProperty annotations={[annotation]} label={AnnotationLabels.Area} />
        <HideLabelProperty annotations={[annotation]} label={AnnotationLabels.Volume} />
        <EditableColorProperty annotations={[annotation]} />
      </PropertiesSection>
    </div>
  );
};
export const AreaProperties = memo($AreaProperties);

const $CadAreaProperties: React.FC2<{ cadPolygon: ICadPolygon }> = ({ cadPolygon }) => {
  const { formatNumber } = useFormatNumber();
  const { perimeterMeasurement } = useMeasurements();

  const renderContext = useContext(RendererContext);
  const viewer = renderContext.viewer;
  const polygonObject = viewer?.scene.measurements.find((m) => m.identifier === cadPolygon.identifier);
  const area = polygonObject?.getArea() || 0;
  const planar = polygonObject?.isPlanar() || false;

  const perimeter = useMemo(() => {
    return perimeterMeasurement(cadPolygon.points);
  }, [cadPolygon.points, perimeterMeasurement]);

  return (
    <div className="flex flex-col space-y-4 divide-y divide-[#515256]">
      <PropertiesSection title={<T _str="information" swc />}>
        <Property title={<T _str="type" swc />}>
          <T _str="polygon" swc />
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="measurements" swc />}>
        <Property title={planar ? <T _str="area (3D)" swc /> : <T _str="area (2D)" swc />}>
          {formatNumber(area, { convertType: ConvertType.AREA, addUnit: true })}
        </Property>
        <Property title={<T _str="perimeter" swc />}>
          {formatNumber(perimeter, { convertType: ConvertType.DISTANCE, addUnit: true })}
        </Property>
      </PropertiesSection>
      <PropertiesSection title={<T _str="appearance" swc />}>
        <StaticColorProperty color={cadPolygon.color} />
      </PropertiesSection>
    </div>
  );
};

export const CadAreaProperties = memo($CadAreaProperties);
