import { useCallback, useContext, useEffect } from 'react';
import { RendererContext } from '../../contexts/RendererContext';
import { Point } from '../../../public/potree/potree/utils/Measure';
import { useCustomSelector } from '../../redux/store';
import { ProjectByIdQuery } from '../../types/graphqlTypes';
import { useExecuteAction } from './useExecuteAction';
import { useAnnotations } from './useRenderer';
import { useSelectAnnotation } from './useSelectAnnotation';
import { useSelectedAnnotations } from './useSelectedAnnotations';
import { useSelectCadItem } from './useSelectCadItem';
import { PointCluster } from '../../../public/potree/potree/utils/PointCluster';
import { PointoramaPlane } from '../../../public/potree/potree/utils/PointoramaPlane';
import { useT } from '../../translation/src';

const useTranslations = () => {
  const point = useT('point', { swc: true });
  const line = useT('line', { swc: true });
  const polygon = useT('polygon', { swc: true });
  const box = useT('box', { swc: true });
  const pointCluster = useT('point cluster', { swc: true });
  const plane = useT('plane', { swc: true });
  return { point, line, polygon, box, pointCluster, plane };
};

export const usePotreeEvents = ({ project }: { project?: ProjectByIdQuery['projectById'] }) => {
  const translations = useTranslations();
  const rendererContext = useContext(RendererContext);
  const viewer = rendererContext.viewer;
  const [executeAction] = useExecuteAction();
  const [{ annotationsByIdentifier }] = useAnnotations();
  const { selectedAnnotations } = useCustomSelector((state) => state.rendererProvider, ['selectedAnnotations']);
  const selectedAnnotationIdentifier =
    selectedAnnotations.length === 1 && selectedAnnotations[0].type === 'annotation'
      ? selectedAnnotations[0].identifier
      : undefined;
  const selectedGroupIdentifier =
    selectedAnnotations.length === 1 && selectedAnnotations[0].type === 'group'
      ? selectedAnnotations[0].identifier
      : undefined;
  const { onClickAnnotation } = useSelectAnnotation();
  const { onClickCadItem } = useSelectCadItem();
  const { allSelectedAnnotations } = useSelectedAnnotations();
  const { selectedCadItems } = useCustomSelector((state) => state.rendererProvider, ['selectedCadItems']);

  useEffect(() => {
    if (!viewer?.scene?.measurements) return;
    viewer.scene.measurements.forEach((measurement) => {
      if (measurement instanceof Potree.PointoramaAnnotation) {
        if (allSelectedAnnotations.some((annotation) => annotation.identifier === measurement.identifier)) {
          measurement.select();
        } else {
          measurement.deselect();
        }
      } else if (measurement instanceof Potree.CadObject) {
        if (selectedCadItems?.some((cadItem) => cadItem.identifier === measurement.identifier)) {
          measurement.select();
        } else {
          measurement.deselect();
        }
      }
    });
    viewer.scene.volumes.forEach((volume) => {
      const selectedPointorama = allSelectedAnnotations.some(
        (annotation) => volume.identifier === annotation.identifier,
      );
      const selectedPotree = volume.selected;
      if (selectedPointorama && !selectedPotree) {
        volume.select();
      } else if (!selectedPointorama && selectedPotree) {
        volume.unselect();
      }
    });
    viewer.scene.pointClusters.forEach((pointCluster) => {
      const selectedPointCluster = allSelectedAnnotations.some(
        (annotation) => pointCluster.identifier === annotation.identifier,
      );

      if (selectedPointCluster) {
        pointCluster.select();
      } else {
        pointCluster.unselect();
      }
    });
    viewer.scene.planes.forEach((plane) => {
      const selectedPlane = allSelectedAnnotations.some((annotation) => plane.identifier === annotation.identifier);

      if (selectedPlane) {
        plane.select();
      } else {
        plane.deselect();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSelectedAnnotations, selectedCadItems]);

  const onVolumePlace = useCallback(
    ({ value }: { value?: { volume: typeof Potree.Volume } }) => {
      const volume = value?.volume;
      if (!volume) return;
      const selectedAnnotation = annotationsByIdentifier[selectedAnnotationIdentifier || ''];
      const groupIdentifier = selectedGroupIdentifier || selectedAnnotation?.groupIdentifier;

      const action = {
        name: translations.box,
        annotationIdentifier: volume.identifier,
        position: { x: volume.position.x, y: volume.position.y, z: volume.position.z },
        scale: { x: volume.scale.x, y: volume.scale.y, z: volume.scale.z },
        rotation: { x: volume.rotation.x, y: volume.rotation.y, z: volume.rotation.z },
        groupIdentifier,
      };
      executeAction({ action, type: 'BOX' });

      return null;
    },
    [annotationsByIdentifier, executeAction, selectedAnnotationIdentifier, selectedGroupIdentifier, translations.box],
  );

  const onVolumeMove = useCallback(
    ({ value }: { value?: { volume: typeof Potree.Volume } }) => {
      const volume = value?.volume;
      if (!volume) return;

      const action = {
        annotationIdentifier: volume.identifier,
        position: { x: volume.position.x, y: volume.position.y, z: volume.position.z },
        scale: { x: volume.scale.x, y: volume.scale.y, z: volume.scale.z },
        rotation: { x: volume.rotation.x, y: volume.rotation.y, z: volume.rotation.z },
      };

      executeAction({ action, type: 'EDIT_BOX' });

      return null;
    },
    [executeAction],
  );

  const onMeasurementFinish = useCallback(
    ({ value }: { value?: { measure: typeof Potree.Measure } }) => {
      if (!value) return;
      const measure = value.measure;
      const selectedAnnotation = annotationsByIdentifier[selectedAnnotationIdentifier || ''];
      const groupIdentifier = selectedGroupIdentifier || selectedAnnotation?.groupIdentifier;
      if (measure.type === 'POINT') {
        const point = measure.points[0];
        if (!point) return;
        const action = {
          name: translations.point,
          annotationIdentifier: measure.identifier,
          position: { x: point.position.x, y: point.position.y, z: point.position.z },
          classification: point.classification?.[0],
          intensity: point.intensity?.[0],
          color: point.color && { r: point.color[0], g: point.color[1], b: point.color[2], a: point.color[3] },
          groupIdentifier,
          pointCloudId: point.pointCloudId,
        };
        executeAction({ action, type: 'POINT' });
      } else if (measure.type === 'DISTANCE') {
        const action = {
          name: translations.line,
          annotationIdentifier: measure.identifier,
          points: measure.points.map((point) => ({
            position: { x: point.position.x, y: point.position.y, z: point.position.z },
            pointCloudId: point.pointCloudId,
          })),
          groupIdentifier,
        };
        executeAction({ action, type: 'DISTANCE' });
      } else if (measure.type === 'AREA') {
        const action = {
          name: translations.polygon,
          annotationIdentifier: measure.identifier,
          points: measure.points.map((point) => ({
            position: { x: point.position.x, y: point.position.y, z: point.position.z },
            pointCloudId: point.pointCloudId,
          })),
          groupIdentifier,
        };
        executeAction({ action, type: 'AREA' });
      }

      return null;
    },
    [
      annotationsByIdentifier,
      executeAction,
      selectedAnnotationIdentifier,
      selectedGroupIdentifier,
      translations.line,
      translations.point,
      translations.polygon,
    ],
  );

  const onMeasurementMovePoint = useCallback(
    ({ value }: { value?: { pointIndex: number; point: Point; measure: typeof Potree.Measure } }) => {
      if (!value || !value.point) return;
      const { pointIndex, point, measure } = value;
      const action = {
        annotationIdentifier: measure.identifier,
        position: { x: point.position.x, y: point.position.y, z: point.position.z },
        pointIndex,
        color: point.color && { r: point.color[0], g: point.color[1], b: point.color[2], a: point.color[3] },
        classification: point.classification?.[0],
        intensity: point.intensity?.[0],
        pointCloudId: point.pointCloudId || '',
      };
      executeAction({ action, type: 'MOVE_POINT' });
    },
    [executeAction],
  );

  const onMeasurementAddPoint = useCallback(
    ({ value }: { value?: { pointIndex: number; point: Point; measure: typeof Potree.Measure } }) => {
      if (!value) return;
      const { pointIndex, point, measure } = value;
      const action = {
        annotationIdentifier: measure.identifier,
        pointCloudId: point.pointCloudId || '',
        position: { x: point.position.x, y: point.position.y, z: point.position.z },
        pointIndex,
      };
      executeAction({ action, type: 'ADD_ADDITIONAL_POINT' });
    },
    [executeAction],
  );

  const onMeasurementDeletePoint = useCallback(
    ({ value }: { value?: { pointIndex: number; measure: typeof Potree.Measure } }) => {
      if (!value) return;
      const { pointIndex, measure } = value;
      const action = {
        annotationIdentifier: measure.identifier,
        pointIndex,
      };
      executeAction({ action, type: 'DELETE_POINT' });
    },
    [executeAction],
  );

  const onMeasurementDeleteAnnotation = useCallback(
    ({ value }: { value?: { measure: typeof Potree.Measure } }) => {
      if (!value) return;
      const action = {
        annotationIdentifier: value.measure.identifier,
      };
      executeAction({ action, type: 'DELETE_ANNOTATION' });
    },
    [executeAction],
  );

  const onMeasurementSelectAnnotation = useCallback(
    ({ value }: { value?: { measure: typeof Potree.Measure; isCtrl: boolean } }) => {
      if (!value) return;
      if (!value.measure) {
        onClickAnnotation({ identifier: '', type: 'annotation' }, { isCtrl: false, isShift: false });
      }
      if (
        value.measure instanceof Potree.PointoramaAnnotation ||
        value.measure instanceof Potree.BoxVolume ||
        value.measure instanceof Potree.PointCluster ||
        value.measure instanceof Potree.PointoramaPlane
      ) {
        onClickAnnotation(
          { identifier: value.measure.identifier, type: 'annotation' },
          { isCtrl: value.isCtrl, isShift: false },
        );
      } else if (value.measure instanceof Potree.CadObject) {
        onClickCadItem(
          { identifier: value.measure.identifier, type: 'cadObject' },
          { isCtrl: value.isCtrl, isShift: false },
        );
      }
    },
    [onClickAnnotation, onClickCadItem],
  );

  const onPointClusterAdded = useCallback(
    ({ value }: { value?: { pointCluster: PointCluster } }) => {
      if (!value) return;
      const action = {
        annotationIdentifier: value.pointCluster.identifier,
        name: translations.pointCluster,
        segments: value.pointCluster.segments,
      };
      executeAction({ action, type: 'POINTCLUSTER' });
    },
    [executeAction, translations.pointCluster],
  );

  const onPlaneAdded = useCallback(
    ({ value }: { value?: { plane: PointoramaPlane } }) => {
      if (!value) return;
      const NormalV3 = value.plane.getNormal();
      const action = {
        annotationIdentifier: value.plane.identifier,
        name: translations.plane,
        position: {
          x: value.plane.position.x,
          y: value.plane.position.y,
          z: value.plane.position.z,
        },
        normal: {
          x: NormalV3.x,
          y: NormalV3.y,
          z: NormalV3.z,
        },
      };
      executeAction({ action, type: 'PLANE' });
    },
    [executeAction, translations.plane],
  );

  const onPointClusterEdit = useCallback(
    ({ value }: { value?: { pointCluster: PointCluster } }) => {
      if (!value) return;
      const action = {
        annotationIdentifier: value.pointCluster.identifier,
        segments: value.pointCluster.segments,
      };
      executeAction({ action, type: 'EDIT_POINTCLUSTER' });
    },
    [executeAction],
  );

  const onPlaneEdit = useCallback(
    ({ value }: { value?: { plane: PointoramaPlane } }) => {
      if (!value) return;
      const NormalV3 = value.plane.getNormal();
      const action = {
        annotationIdentifier: value.plane.identifier,
        position: {
          x: value.plane.position.x,
          y: value.plane.position.y,
          z: value.plane.position.z,
        },
        normal: {
          x: NormalV3.x,
          y: NormalV3.y,
          z: NormalV3.z,
        },
      };
      executeAction({ action, type: 'EDIT_PLANE' });
    },
    [executeAction],
  );

  // const onPerformanceCheck = useCallback(({ value }: { value?: { fps: number; consecutiveHits: number } }) => {
  //   if (!value) return;
  //   if (value.consecutiveHits > 2) {
  //     // Just added consecutiveHits to filter out fps drops when loading, switching browsers/pages, ...
  //     dispatch(showPerformanceWarning({ visible: true }));
  //   }
  // }, []);

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.addEventListener('measurement_finish', onMeasurementFinish);
    return () => {
      viewer.scene.removeEventListener('measurement_finish', onMeasurementFinish);
    };
  }, [onMeasurementFinish, viewer]);

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.addEventListener('measurement_move_point', onMeasurementMovePoint);
    return () => {
      viewer.scene.removeEventListener('measurement_move_point', onMeasurementMovePoint);
    };
  }, [onMeasurementMovePoint, viewer]);

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.addEventListener('measurement_add_point', onMeasurementAddPoint);
    return () => {
      viewer.scene.removeEventListener('measurement_add_point', onMeasurementAddPoint);
    };
  }, [onMeasurementAddPoint, viewer]);

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.addEventListener('measurement_delete_point', onMeasurementDeletePoint);
    return () => {
      viewer.scene.removeEventListener('measurement_delete_point', onMeasurementDeletePoint);
    };
  }, [onMeasurementDeletePoint, viewer]);

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.addEventListener('measurement_delete', onMeasurementDeleteAnnotation);
    return () => {
      viewer.scene.removeEventListener('measurement_delete', onMeasurementDeleteAnnotation);
    };
  }, [onMeasurementDeleteAnnotation, viewer]);

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.addEventListener('measurement_select', onMeasurementSelectAnnotation);
    return () => {
      viewer.scene.removeEventListener('measurement_select', onMeasurementSelectAnnotation);
    };
  }, [onMeasurementSelectAnnotation, viewer]);

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.addEventListener('pointorama_volume_placed', onVolumePlace);
    return () => {
      viewer.scene.removeEventListener('pointorama_volume_placed', onVolumePlace);
    };
  }, [onVolumePlace, viewer]);

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.addEventListener('pointorama_volume_moved', onVolumeMove);
    return () => {
      viewer.scene.removeEventListener('pointorama_volume_moved', onVolumeMove);
    };
  }, [onVolumeMove, viewer]);

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.addEventListener('pointorama_pointCluster_added', onPointClusterAdded);
    return () => {
      viewer.scene.removeEventListener('pointorama_pointCluster_added', onPointClusterAdded);
    };
  }, [onPointClusterAdded, viewer]);

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.addEventListener('pointorama_pointCluster_edit', onPointClusterEdit);
    return () => {
      viewer.scene.removeEventListener('pointorama_pointCluster_edit', onPointClusterEdit);
    };
  }, [onPointClusterEdit, viewer]);

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.addEventListener('pointorama_plane_added', onPlaneAdded);
    return () => {
      viewer.scene.removeEventListener('pointorama_plane_added', onPlaneAdded);
    };
  }, [onPlaneAdded, viewer]);

  useEffect(() => {
    if (!viewer) return;
    viewer.scene.addEventListener('pointorama_plane_edit', onPlaneEdit);
    return () => {
      viewer.scene.removeEventListener('pointorama_plane_edit', onPlaneEdit);
    };
  }, [onPlaneEdit, viewer]);

  // useEffect(() => {
  //   if (!viewer) return;
  //   viewer.scene.addEventListener('performance_check', onPerformanceCheck);
  //   return () => {
  //     viewer.scene.removeEventListener('performance_check', onPerformanceCheck);
  //   };
  // }, [onPerformanceCheck, viewer]);
};
