import { FormikInput } from '../../components/formik/FormInput';
import { CreateModal } from '../../components/Modal/CreateModal';
import { useValidationTranslations } from '../../hooks/useValidationTranslations';
import { T, useT } from '../../translation/src';
import { ApiKeysDocument, useCreateApiKeyMutation } from '../../types/graphqlTypes';
import { FormikConfig, FormikHelpers, useField } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { ApiKeyType } from '../../types/graphqlTypes';
import { FormikSelect } from '../../components/formik/FormSelect';
import { useKeyTypeOptions } from '../../hooks/useKeyTypeOptions';
import { Option } from '../../components/inputs/Select';

export const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object({
        name: yup.string().trim().required(translations.isRequired),
        keyType: yup.string().required(translations.isRequired).oneOf(Object.values(ApiKeyType)),
        licenseKey: yup.string().when('keyType', {
          is: ApiKeyType.WithLicenseKey,
          then: () => yup.string().trim().required(translations.isRequired),
        }),
      }),
    [translations],
  );

  return schema;
};

interface FormValues {
  name: string;
  keyType: ApiKeyType;
  licenseKey: string;
}

const useTranslations = () => {
  const name = useT('name ', { swc: true });
  const keyType = useT('key type ', { swc: true });
  const licenseKey = useT('license key', { swc: true });
  return { name, keyType, licenseKey };
};

const LicenseKeyInput = () => {
  const translations = useTranslations();
  const [{ value: keyTypeValue }] = useField<ApiKeyType>('keyType');

  return (
    <FormikInput
      className={keyTypeValue !== ApiKeyType.WithLicenseKey ? 'hidden' : ''}
      name="licenseKey"
      label={translations.licenseKey}
      placeholder={translations.licenseKey}
    />
  );
};

interface ApiKeyModalProps {
  onClose: () => void;
  open?: boolean;
}
const $ApiKeyModal: React.FC2<ApiKeyModalProps> = ({ onClose, open }) => {
  const validationSchema = useValidationSchema();
  const translations = useTranslations();
  const { organisationId = '' } = useParams();
  const [addApiKey, { loading: addApiKeyLoading }] = useCreateApiKeyMutation();
  const keyTypeOptions = useKeyTypeOptions();

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      onClose();
      // Because of transition
      setTimeout(() => {
        resetForm();
      }, 250);
    },
    [onClose],
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values, helpers) => {
      await addApiKey({
        variables: { organisationId, ...values },
        refetchQueries: [ApiKeysDocument],
        awaitRefetchQueries: true,
      });
      onSuccess({ helpers });
    },
    [addApiKey, onSuccess, organisationId],
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: { name: '', keyType: ApiKeyType.Default, licenseKey: '' },
      onSubmit,
      validationSchema,
    }),
    [onSubmit, validationSchema],
  );

  return (
    <CreateModal
      title={<T _str="add API key" swc />}
      createButtonTitle={<T _str="create key" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
      isSubmitting={addApiKeyLoading}
    >
      <FormikInput autoFocus name="name" label={translations.name} placeholder={translations.name} />
      <FormikSelect name="keyType" label={translations.keyType} placeholder={translations.keyType}>
        {keyTypeOptions.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </FormikSelect>
      <LicenseKeyInput />
    </CreateModal>
  );
};

export const ApiKeyModal = memo($ApiKeyModal);
