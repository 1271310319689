import Logo from '../../assets/logo-same-color.svg?react';
import { Option } from '../../components/inputs/Select';
import { Form, Formik, FormikConfig } from 'formik';
import { memo, useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { Button } from '../../components/Button';
import { FormikCheckBox } from '../../components/formik/FormCheckBox';
import { FormikInput } from '../../components/formik/FormInput';
import { FormikSelect } from '../../components/formik/FormSelect';
import { EarthLoader } from '../../components/loaders/EarthLoader';
import { useValidationTranslations } from '../../hooks/useValidationTranslations';
import { T, useT } from '../../translation/src';
import { useHubspotSectorsQuery } from '../../types/graphqlTypes';

const useSectorTranslations = () => ({
  'Topographic survey': <T _str="topographic survey" swc />,
  'Hydrographic survey': <T _str="hydrographic survey" swc />,
  'Aerial survey': <T _str="aerial survey" swc />,
  'Road construction': <T _str="road construction" swc />,
  'Building construction': <T _str="building construction" swc />,
  'Earthmoving & Demolition': <T _str="earthmoving & demolition" swc />,
  Dredging: <T _str="dredging" swc />,
  Other: <T _str="other" swc />,
});
export const useSectorOptions = () => {
  const translations = useSectorTranslations();
  const { data, loading } = useHubspotSectorsQuery();

  const options = useMemo(() => {
    if (!loading && !data?.hubspotSectors) return [{ value: 'Other', label: translations.Other }];
    return (
      data?.hubspotSectors.map((sector) => ({
        ...sector,
        label: translations[sector.value as keyof typeof translations] || sector.value,
      })) || []
    );
  }, [data?.hubspotSectors, loading, translations]);

  return { options, loading };
};

const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        organisationName: yup.string().trim().required(translations.isRequired),
        sector: yup.string().trim().required(translations.isRequired),
        firstName: yup.string().trim().required(translations.isRequired),
        lastName: yup.string().trim().required(translations.isRequired),
        email: yup.string().trim().email(translations.validEmail).required(translations.isRequired),
        agreeTerms: yup.bool().oneOf([true], translations.agreeTerms),
        agreeReceiveNews: yup.bool().oneOf([true, false]),
        password: yup.string().trim().required(translations.isRequired),
        confirmPassword: yup
          .string()
          .required(translations.isRequired)
          .oneOf([yup.ref('password')], translations.passwordsMustMatch),
      }),
    [translations],
  );

  return schema;
};

interface FormValues {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  organisationName: string;
  agreeTerms: boolean;
  agreeReceiveNews: boolean;
}

const useTranslations = () => ({
  organisationName: useT('organisation name', { swc: true }),
  firstName: useT('first name', { swc: true }),
  lastName: useT('last name', { swc: true }),
  email: useT('email', { swc: true }),
  password: useT('password', { swc: true }),
  confirmPassword: useT('confirm password', { swc: true }),
  alreadyExists: useT('already exists', { swc: true }),
  sector: useT('sector', { swc: true }),
  industry: useT('industry', { swc: true }),
});

const $Register: React.FC2 = () => {
  const sectorOptions = useSectorOptions();
  const translations = useTranslations();
  const validationTranslations = useValidationTranslations();
  const navigate = useNavigate();
  const validationSchema = useValidationSchema();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    async (values, helpers) => {
      try {
        setIsSubmitting(true);
        const response = await fetch(`${import.meta.env.VITE_NX_SERVER}/auth/register`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(values),
          credentials: 'include',
        });
        if (response.status === 200) return navigate('/');
        const jsonResponse = await response.json();
        if (jsonResponse.message === 'ALREADY_EXISTS')
          helpers.setFieldError(jsonResponse.field, translations.alreadyExists);
        else toast.error(validationTranslations.somethingWentWrong);
      } catch (e) {
        console.log('e', e);
      } finally {
        setIsSubmitting(false);
      }
    },
    [navigate, translations.alreadyExists, validationTranslations.somethingWentWrong],
  );

  const initialValues: FormikConfig<FormValues>['initialValues'] = useMemo(
    () => ({
      email: '',
      password: '',
      organisationName: '',
      firstName: '',
      lastName: '',
      confirmPassword: '',
      agreeReceiveNews: false,
      agreeTerms: false,
      sector: '',
    }),
    [],
  );

  if (sectorOptions.loading) return <EarthLoader />;

  return (
    <div className="flex items-center justify-center w-full min-h-full px-4 py-12 sm:px-6 lg:px-8 dark:bg-[#313131] dark:text-white overflow-auto h-full">
      <div className="flex flex-col w-full h-full max-w-md space-y-8 overflow-auto">
        <div>
          <Logo className="w-auto mx-auto" />
          <h2 className="mt-6 text-3xl font-bold tracking-tight text-center text-gray-900 dark:text-white">
            <T _str="Create an account" />
          </h2>
          <p className="mt-2 text-sm text-center text-gray-600 dark:text-white">
            <T _str="Or" />{' '}
            <Link
              className="font-medium text-french-violet-500 hover:text-french-violet-400 dark:text-neon-green-500 dark:hover:text-neon-green-400"
              to="/login"
            >
              <T _str="sign in to your account" />
            </Link>
          </p>
        </div>
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
          {/* @ts-ignore */}
          <Form className="px-2 mt-8 overflow-auto">
            <FormikInput
              autoFocus
              name="organisationName"
              label={translations.organisationName}
              placeholder={translations.organisationName}
            />
            <div className="mb-2 text-sm italic text-gray-500 dark:text-gray-300">
              <T _str="Do you want to join an existing organisation? Request an invite from the organisation admin to get access." />
            </div>
            <FormikSelect name="sector" label={translations.industry} placeholder={translations.industry}>
              {sectorOptions.options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </FormikSelect>
            <FormikInput name="firstName" label={translations.firstName} placeholder={translations.firstName} />
            <FormikInput name="lastName" label={translations.lastName} placeholder={translations.lastName} />
            <FormikInput
              name="email"
              autoComplete="email"
              label={translations.email}
              placeholder={translations.email}
            />
            <FormikInput
              name="password"
              type="password"
              autoComplete="current-password"
              label={translations.password}
            />
            <FormikInput name="confirmPassword" type="password" label={translations.confirmPassword} />
            <FormikCheckBox
              name="agreeTerms"
              label={
                <span>
                  <T _str="I agree to Pointorama's Terms and Privacy Policy" />
                </span>
              }
            />
            <FormikCheckBox
              name="agreeReceiveNews"
              label={<T _str="I agree to receive Pointorama's news and updates " />}
            />
            <div className="mt-6">
              <Button
                variant="primary"
                type="submit"
                className="items-center justify-center w-full"
                loading={isSubmitting}
              >
                <T _str="get started - free" swc />
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export const Register = memo($Register);
