import { memo } from 'react';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import { Button } from '../Button';
import { PlusIcon } from '@heroicons/react/24/outline';

interface PageHeaderPropsCommon {
  title?: JSX.Element | null;
}
interface PageHeaderPropsWithCustomButton extends PageHeaderPropsCommon {
  button?: JSX.Element | null;
}
interface PageHeaderPropsWithButton extends PageHeaderPropsCommon {
  buttonTitle?: JSX.Element | null;
  onClickButton: () => void;
}

export const $PageHeader: React.FC2<PageHeaderPropsWithCustomButton | PageHeaderPropsWithButton> = ({
  title,
  ...props
}) => {
  const { xsDevice } = useDeviceSize();
  const isWithCustomButton = 'button' in props;
  const isWithButtonProps = 'onClickButton' in props;

  return (
    <div className="z-20 w-full pt-8 mb-6 text-xl">
      <div className="relative flex flex-col w-full">
        <div className={xsDevice ? 'w-24' : ''}>{title}</div>
        {isWithCustomButton ? <div className="absolute right-0 -top-2">{props.button}</div> : null}
        {isWithButtonProps ? (
          <div className="absolute right-0 -top-2">
            <Button variant="primary" onClick={props.onClickButton} size="sm">
              <PlusIcon className={xsDevice ? 'w-4 mr-0' : 'w-4 mr-1'} />
              {!xsDevice ? props.buttonTitle : null}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const PageHeader = memo($PageHeader);
