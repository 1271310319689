import { InfoContent } from '../../components/InfoContent';
import { SubmitModal } from '../../components/Modal/SubmitModal';
import { T } from '../../translation/src';
import { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { ApiKeysDocument, useDeleteApiKeyMutation } from '../../types/graphqlTypes';

interface DeleteApiKeyModalProps {
  onClose: () => void;
  open?: boolean;
  apiKey?: { apiKey: string; name: string } | null;
}
const $DeleteApiKeyModal: React.FC2<DeleteApiKeyModalProps> = ({ onClose, open, apiKey }) => {
  const [deleteApiKey, { loading: isSubmitting }] = useDeleteApiKeyMutation();
  const { organisationId = '' } = useParams();

  const onSubmit = useCallback(() => {
    if (!apiKey) return;
    deleteApiKey({
      variables: { apiKey: apiKey.apiKey, organisationId },
      onCompleted: onClose,
      refetchQueries: [ApiKeysDocument],
    });
  }, [apiKey, deleteApiKey, onClose, organisationId]);

  return (
    <SubmitModal
      title={<T _str="remove {name} from organisation" swc args={{ name: apiKey?.name || '' }} />}
      actionButtonTitle={<T _str="delete API key" swc />}
      onClose={onClose}
      open={open}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
    >
      <InfoContent
        type="warning-red"
        title={<T _str="This action cannot be undone!" swc />}
        description={
          <T _str="Deleting this API key can not be undone. Are you sure you want to remove this API key from the organisation?" />
        }
      />
    </SubmitModal>
  );
};

export const DeleteApiKeyModal = memo($DeleteApiKeyModal);
