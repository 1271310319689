import { useQuery } from '@apollo/client';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '../../components/Button';
import { useStatusTranslations } from '../../components/Pill';
import { UploadCard } from '../../components/UploadCard';
import { Option, Select, SelectProps } from '../../components/inputs/Select';
import { UserContext } from '../../contexts/UserContext';
import { USERS } from '../../graphql/user';
import { usePointCloudDrop, usePointCloudDropWithModals } from '../../hooks/modules/project/usePointCloudDrop';
import { useRecentProjects } from '../../hooks/modules/project/useRecentProjects';
import useOpen from '../../hooks/useOpen';
import useQueryParams from '../../hooks/useQueryParams';
import { ProjectCard } from '../../modules/project/ProjectCard';
import { ShouldUpgradeModal } from '../../modules/user/ShouldUpgradeModal';
import { T, useT } from '../../translation/src';
import {
  UploadStatus,
  UserRole,
  UsersQuery,
  UsersQueryVariables,
  useOrganisationsQuery,
  useProjectsQuery,
} from '../../types/graphqlTypes';
import { useDeviceSize } from '../../hooks/useDeviceSize';
import classNames from 'classnames';
import { PageContent } from '../../components/page/PageContent';
import { PageHeader } from '../../components/page/PageHeader';

const useTranslations = () => {
  const shouldUpgradeModalDescriptionProject = useT('In order to add more projects, you need to upgrade your plan.');
  const shouldUpgradeModalDescriptionUpload = useT(
    'In order to add more simultaneous uploads, you need to upgrade your plan.',
  );
  return { shouldUpgradeModalDescriptionProject, shouldUpgradeModalDescriptionUpload };
};

export const Projects: React.FC = () => {
  const { organisationId = '' } = useParams<{ organisationId: string }>();
  const { projectsSelect } = useQueryParams(['projectsSelect']);
  const projectsQuery = useProjectsQuery({ variables: { organisationId } });
  const organsiationsQuery = useOrganisationsQuery();
  useEffect(() => {
    // pollInterval not working at the moment in apollo
    projectsQuery.startPolling(15000);
    organsiationsQuery.startPolling(15000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    open: shouldUpgradeModalOpen,
    onClose: closeShouldUpgradeModal,
    onOpenWithValue: openShouldUpgradeModal,
    value: shouldUpgradeModalDescription,
  } = useOpen<string>();

  const usersQuery = useQuery<UsersQuery, UsersQueryVariables>(USERS, { variables: { organisationId } });
  const translations = useTranslations();
  const users = usersQuery.data?.usersByOrganisationId || [];
  const statusTranslations = useStatusTranslations();
  const [filters, setFilters] = useState({ status: 'ALL', assigneeId: 'ALL' });
  const [recentProjects] = useRecentProjects();
  const currentUser = useContext(UserContext);
  const { data: organisations } = useOrganisationsQuery(
    currentUser ? { variables: { includeIds: [organisationId] } } : {},
  );
  const [{ modals: uploadModals }, { onNewFiles }] = usePointCloudDropWithModals();
  const [, { getRootProps, getInputProps }] = usePointCloudDrop({ onNewFiles });
  const { smDevice } = useDeviceSize();
  const orderedUploadStatus = [UploadStatus.Progress, UploadStatus.Success, UploadStatus.Suspended, UploadStatus.Error];

  const projects = useMemo(() => {
    return (projectsQuery.data?.projectsByOrganisationId || [])
      .filter((project) => {
        if (project.isInitializing) return false;
        if (filters.status !== 'ALL') {
          if (filters.status !== project.uploadStatus) return false;
        }
        if (filters.assigneeId !== 'ALL') {
          if (filters.assigneeId !== project.assignee?.id) return false;
        }
        if (projectsSelect === 'recent') {
          if (!recentProjects.includes(project.id)) return false;
        }
        if (projectsSelect === 'favorites') {
          if (!currentUser.favoriteProjectIds.includes(project.id)) return false;
        }
        return true;
      })
      .reverse();
  }, [
    filters.assigneeId,
    filters.status,
    projectsQuery.data?.projectsByOrganisationId,
    projectsSelect,
    recentProjects,
    currentUser,
  ]);

  const onFilter: SelectProps<string | number>['onChange'] = useCallback((event) => {
    setFilters((filters) => ({ ...filters, [event.target.name]: event.target.value }));
  }, []);

  const organisation = organisations?.organisations?.find((organisation) => organisation.id === organisationId);

  const isReadOnly =
    !currentUser.isSuperAdmin &&
    currentUser.rolesByOrganisation.find((value) => value.organisationId === organisationId)?.role === UserRole.Guest;

  const addProjectButton = useMemo(() => {
    if (!organisation || isReadOnly) return;
    if (
      (organisation.subscription.canAddPointClouds && organisation.subscription.canAddSimultaneousUploads) ||
      currentUser.isSuperAdmin
    ) {
      return (
        <Button {...getRootProps()} variant="primary">
          <PlusIcon className={smDevice ? 'w-5' : 'w-5 mr-2'} />
          {!smDevice && <T _str="add new project" swc />}
          <input type="file" className="sr-only" {...getInputProps()} accept=".las,.pts,.laz,.e57" />
        </Button>
      );
    }
    return (
      <Button
        className="absolute right-0 top-8"
        variant="primary"
        onClick={() =>
          openShouldUpgradeModal(
            !organisation.subscription.canAddPointClouds
              ? translations.shouldUpgradeModalDescriptionProject
              : translations.shouldUpgradeModalDescriptionUpload,
          )
        }
      >
        <PlusIcon className="w-5 mr-2" />
        <T _str="add new project" swc />
      </Button>
    );
  }, [
    organisation,
    isReadOnly,
    currentUser.isSuperAdmin,
    smDevice,
    getRootProps,
    getInputProps,
    openShouldUpgradeModal,
    translations.shouldUpgradeModalDescriptionProject,
    translations.shouldUpgradeModalDescriptionUpload,
  ]);

  const pageHeaderTitle = useMemo(() => {
    if (projectsSelect === 'recent') {
      return <T _str="recent projects" swc />;
    }
    if (projectsSelect === 'favorites') {
      return <T _str="favorite projects" swc />;
    }
    if (!projectsSelect || projectsSelect === 'all') {
      return <T _str="all projects" swc />;
    }
    return null;
  }, [projectsSelect]);

  return (
    <>
      <PageContent>
        <div className={smDevice ? 'relative z-20 pt-4 text-xl' : 'relative z-20 pt-10 text-xl'}>
          <PageHeader title={pageHeaderTitle} button={addProjectButton} />

          {!smDevice && (
            <div className="flex space-x-4 mt-14">
              <Select
                onChange={onFilter}
                value={filters.status}
                name="status"
                isLabelPlaceholder
                placeholder={<T _str="status" swc />}
                className="w-40"
              >
                {['ALL', ...Object.values(orderedUploadStatus)].map((status) => (
                  <Option key={status} value={status}>
                    {statusTranslations[status.toLocaleLowerCase()] || <T _str="all" swc />}
                  </Option>
                ))}
              </Select>
              <Select
                onChange={onFilter}
                value={filters.assigneeId}
                name="assigneeId"
                isLabelPlaceholder
                placeholder={<T _str="uploaded by" swc />}
                className="w-72"
              >
                {[{ id: 'ALL', name: '' }, ...users].map((user) => (
                  <Option key={user.id} value={user.id}>
                    {user.name || <T _str="all" swc />}
                  </Option>
                ))}
              </Select>
            </div>
          )}
        </div>

        <div className={classNames('w-full h-full overflow-auto', smDevice ? 'mt-4' : 'mt-10')}>
          <div
            className={classNames(
              'grid w-full gap-8 rounded-md grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 4xl:grid-cols-6 5xl:grid-cols-7 6xl:grid-cols-8 7xl:grid-cols-9 8xl:grid-cols-10 9xl:grid-cols-11 10xl:grid-cols-12 4k:grid-cols-13',
            )}
          >
            {(organisation?.subscription.canAddPointClouds &&
              organisation?.subscription.canAddSimultaneousUploads &&
              !isReadOnly) ||
            currentUser.isSuperAdmin ? (
              <>
                <UploadCard />
                {currentUser.isSuperAdmin && <UploadCard isPythagorasFile isSuperAdmin />}
              </>
            ) : null}
            {projects.map((project) => (
              <ProjectCard key={project.id} project={project} isReadOnly={isReadOnly} />
            ))}
          </div>
        </div>
      </PageContent>
      {uploadModals}
      <ShouldUpgradeModal
        open={shouldUpgradeModalOpen}
        onClose={closeShouldUpgradeModal}
        description={shouldUpgradeModalDescription || ''}
      />
    </>
  );
};
