import gql from 'graphql-tag';

const ORGANISATION_FRAGMENT = gql`
  fragment Organisations on Organisation {
    id
    name
    usersAmount
    myPermissions
    stats {
      totalPointCount
      totalMonthlyPointCount
      totalPointCountLeft
      totalMonthlyPointCountLeft
      totalUsers
      totalProjects
    }
    isRootOrganisation
    subscription {
      type
      isFreeTrial
      endDate
      canAddPointClouds
      canAddSimultaneousUploads
      simultaneousUploadsLeft
    }
    prohibitedActions
    createdAt
  }
`;

export const ORGANISATIONS = gql`
  ${ORGANISATION_FRAGMENT}
  query organisations($includeIds: [ID!]) {
    organisations(includeIds: $includeIds) {
      ...Organisations
    }
  }
`;

export const ORGANISATION_UPDATE = gql`
  mutation organisationUpdate($organisationId: ID!, $name: String!) {
    organisationUpdate(organisationId: $organisationId, name: $name) {
      id
      name
    }
  }
`;

export const ORGANISATIONS_CREATE = gql`
  ${ORGANISATION_FRAGMENT}
  mutation organisationCreate($name: String!, $sector: String!) {
    organisationCreate(name: $name, sector: $sector) {
      ...Organisations
    }
  }
`;

export const ORGANISATION_DELETE = gql`
  mutation organisationDelete($organisationId: ID!) {
    organisationDelete(organisationId: $organisationId)
  }
`;
