import { FormikSelect } from '../../../components/formik/FormSelect';
import { Option } from '../../../components/inputs/Select';
import { CreateModal } from '../../../components/Modal/CreateModal';
import { AnnotationContextGroup } from '../../../contexts/AnnotationContext';
import { useExecuteAction } from '../../../hooks/potree/useExecuteAction';
import { useAnnotations } from '../../../hooks/potree/useRenderer';
import { useValidationTranslations } from '../../../hooks/useValidationTranslations';
import { T } from '../../../translation/src';
import classNames from 'classnames';
import { FormikConfig, FormikHelpers } from 'formik';
import { memo, useCallback, useMemo } from 'react';
import * as yup from 'yup';

export const useValidationSchema = () => {
  const translations = useValidationTranslations();

  const schema = useMemo(
    () =>
      yup.object().shape({
        groupIdentifier: yup.string().trim().required(translations.isRequired),
      }),
    [translations],
  );

  return schema;
};

interface FormValues {
  groupIdentifier: string;
}

interface MoveAnnotationsGroupModalProps {
  onClose: () => void;
  open?: boolean;
  annotationIdentifiers: string[];
  ignoreGroupIdentifier?: string | null;
}
const $MoveAnnotationsGroupModal: React.FC2<MoveAnnotationsGroupModalProps> = ({
  onClose,
  open,
  annotationIdentifiers,
  ignoreGroupIdentifier,
}) => {
  const validationSchema = useValidationSchema();
  const [{ annotations }] = useAnnotations();
  const groups = useMemo(
    () =>
      annotations.filter(
        (annotation) => annotation.__typename === 'AnnotationGroup' && annotation.identifier !== ignoreGroupIdentifier,
      ) as AnnotationContextGroup[],
    [annotations, ignoreGroupIdentifier],
  );
  const [executeAction] = useExecuteAction();

  const onSuccess = useCallback(
    ({ helpers: { resetForm } }: { helpers: FormikHelpers<FormValues> }) => {
      onClose();
      // Because of transition
      setTimeout(() => {
        resetForm();
      }, 250);
    },
    [onClose],
  );

  const onSubmit: FormikConfig<FormValues>['onSubmit'] = useCallback(
    (values, helpers) => {
      executeAction({
        type: 'MOVE_ANNOTATIONS_TO_GROUP',
        action: { annotationIdentifiers, groupIdentifier: values.groupIdentifier },
      });
      onSuccess({ helpers });
    },
    [annotationIdentifiers, executeAction, onSuccess],
  );

  const formik: FormikConfig<FormValues> = useMemo(
    () => ({
      initialValues: { groupIdentifier: groups[0]?.identifier || '' },
      onSubmit,
      validationSchema,
    }),
    [groups, onSubmit, validationSchema],
  );

  return (
    <CreateModal
      title={<T _str="move object(s) to group" swc />}
      createButtonTitle={<T _str="move" swc />}
      formik={formik}
      onClose={onClose}
      open={open}
    >
      <div className={classNames('grid grid-cols-1 gap-6 mb-6')}>
        <FormikSelect name="groupIdentifier">
          {groups.map((group) => (
            <Option key={group.identifier} value={group.identifier}>
              {group.name}
            </Option>
          ))}
        </FormikSelect>
      </div>
    </CreateModal>
  );
};

export const MoveAnnotationsGroupModal = memo($MoveAnnotationsGroupModal);
