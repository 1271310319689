import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useBoolean } from 'usehooks-ts';
import { EarthLoader } from '../../components/loaders/EarthLoader';
import { UserContext } from '../../contexts/UserContext';
import { useStripeModule } from '../../hooks/modules/billing/useStripeModule';
import { BillingCompanyInfo } from '../../modules/billing/BillingCompanyInfo';
import { T } from '../../translation/src';
import { useOrganisationsQuery } from '../../types/graphqlTypes';
import { BillingCompanyStatistics } from '../../modules/billing/BillingCompanyStatistics';
import { PageHeader } from '../../components/page/PageHeader';
import { PageContent } from '../../components/page/PageContent';

export const CompanyInfo: React.FC2 = () => {
  const user = useContext(UserContext);
  const { organisationId = '' } = useParams();
  const { loading } = useOrganisationsQuery(user ? { variables: { includeIds: [organisationId] } } : {});
  const { setTrue: setStripeLoaded, value: stripeLoaded } = useBoolean();
  const stripe = useStripeModule();

  useEffect(() => {
    stripe.then(() => setStripeLoaded());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContent>
      <PageHeader title={<T _str="organisation" swc />} />

      <div className="flex w-full h-full overflow-auto">
        <div className="flex w-full h-full overflow-auto">
          {loading || !stripeLoaded ? (
            <EarthLoader />
          ) : (
            <div className="flex flex-col w-full space-y-4">
              <BillingCompanyInfo />
              <BillingCompanyStatistics />
            </div>
          )}
        </div>
      </div>
    </PageContent>
  );
};
