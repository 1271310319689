import {
  Popover as HSPopover,
  PopoverButton as HSPopoverButton,
  PopoverPanel as HSPopoverPanel,
} from '@headlessui/react';
import classNames from 'classnames';
import { memo } from 'react';
import { ListItem, ListItemProps } from '../ListItem';

export const $PopoverListItem: React.FC2<ListItemProps> = ({ children, ...props }) => {
  return <ListItem {...props}>{children}</ListItem>;
};
export const PopoverListItem = memo($PopoverListItem);

type ChildFunction = (props: { open: boolean; close: () => void }) => React.ReactNode;
export interface PopoverProps {
  buttonChild: React.ReactNode;
  buttonClassName?: string;
  panelClassName?: string;
  children: ChildFunction | React.ReactNode;
  className?: string;
}
const $Popover: React.FC<PopoverProps> = ({
  buttonChild,
  buttonClassName,
  panelClassName,
  children,
  className,
  ...props
}) => {
  return (
    <HSPopover className={classNames(!className?.includes('absolute') && 'relative', className)} {...props}>
      {({ open, close }) => (
        <>
          <HSPopoverButton className={classNames(buttonClassName, 'flex items-center justify-center')}>
            {buttonChild}
          </HSPopoverButton>

          <HSPopoverPanel
            transition
            anchor="bottom end"
            className="'absolute z-10 transform right-0 bg-white p-2 flex flex-col w-48 border border-gray-300 shadow-sm dark:bg-[#313131] dark:text-white dark:border-gray-500 divide-white/5 rounded-xl transition duration-200 ease-in-out [--anchor-gap:var(--spacing-5)] data-[closed]:-translate-y-1 data-[closed]:opacity-0"
          >
            {children === 'function' ? (children as any)({ open, close }) : children}
          </HSPopoverPanel>
        </>
      )}
    </HSPopover>
  );
};

export const Popover = memo($Popover);
